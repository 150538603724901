import React, { useRef, useEffect, useState } from "react";
import { Flex, Text, Box, useColorModeValue } from "@chakra-ui/react";
import {
  useTable,
  useRowSelect,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { useMemo } from "react";
import TableDropdown from "components/TableDropdown";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CloseCircle } from "components/Icons/Icons";
import { useTranslation } from "react-i18next";
import IndeterminateCheckbox from "components/IndeterminateCheckbox";
import { useToast } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import DotsLoader from "components/DotsLoader";
import centralApi from "centralApi";
import api from "api";
import Pagination from "components/Pagination";

const PaymentTable = ({ currency, initPayments, search, discount }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const searchQuery = new URLSearchParams(location.search);
  const textColor = useColorModeValue("gray.700", "white");

  const user = useSelector((state) => state.user);
  const toast = useToast();

  const form = useRef();
  const [mode, setMode] = useState("");
  const [payments, setPayments] = useState([]);
  const [paymentId, setPaymentId] = useState("");
  const [payIsLoading, setPayIsLoading] = useState(false);
  const [error, setIsError] = useState(false);

  const filteredPayments = useMemo(() => {
    return payments.filter((i) => i?.currency && i.currency === currency);
  }, [payments, currency]);

  const columns = useMemo(
    () => [
      {
        Header: t("portal_payments_table_column_1_title"),
        accessor: "created",
        Cell: ({ value }) =>
          value ? (
            <span title={moment(value).format("HH:mm MMM DD YYYY")}>
              {moment(value).format("DD/MM/YYYY")}
            </span>
          ) : (
            "-"
          ),
        width: 70,
        maxWidth: 70,
        minWidth: 70,
        sortType: (a, b) => {
          if (
            new Date(a.values.created).getTime() <
            new Date(b.values.created).getTime()
          ) {
            return -1;
          } else if (
            new Date(a.values.created).getTime() >
            new Date(b.values.created).getTime()
          ) {
            return 1;
          }

          return 0;
        },
      },
      {
        Header: t("portal_payments_table_column_2_title"),
        accessor: "userName",
        Cell: (value) => {
          return (
            <Link to={`/admin/customers/${value.cell.row.original.userId}`}>
              {!!value.cell.row.original?.isTestUser && "(Test) "}
              <u>
                {`${value.cell.row.original.user?.firstName?.trim()} ${
                  value.cell.row.original.user?.lastName
                }`}
              </u>
            </Link>
          );
        },
      },
      {
        Header: t("portal_payments_table_column_5_title"),
        accessor: "type",
        Cell: ({ value }) => value.toUpperCase(),
        width: 50,
        maxWidth: 50,
        minWidth: 50,
      },
      {
        Header: t("portal_payments_table_column_4_title"),
        accessor: "consumerTotalUK",
        Cell: (value) => (
          <b>
            {currency === "gbp" ? "£" : "€"}
            {(
              value.cell.row.original?.[
                currency === "gbp" ? "consumerTotalUK" : "consumerTotalHU"
              ] / 100
            ).toFixed(2)}
          </b>
        ),
        width: 70,
        maxWidth: 70,
        minWidth: 70,
      },
      {
        Header: t("portal_payments_table_column_6_title"),
        accessor: "totalUK",
        Cell: (value) => (
          <b>
            {currency === "gbp" ? "£" : "€"}
            {(
              value.cell.row.original?.[
                currency === "gbp" ? "totalUK" : "totalHU"
              ] / 100
            ).toFixed(2)}
          </b>
        ),
        width: 70,
        maxWidth: 70,
        minWidth: 70,
      },
    ],
    [currency]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: filteredPayments,
      initialState: {
        selectedRowIds: filteredPayments.reduce(
          (prev, curr, index) => ({
            ...prev,
            [index]: searchQuery.get("oid")
              ? curr.id === searchQuery.get("oid")
              : true,
          }),
          {}
        ),
        pageSize: 10,
      },
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="table-select">
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div className="table-select">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
        {
          id: "action",
          Header: () => "Manage",
          Cell: ({ row }) => (
            <TableDropdown>
              {/* <div 
                          className="dropdown-item"
                          
                        >
                            Cancel order
                        </div> */}
              <div
                className="dropdown-item"
                onClick={() => removeItem(row.original.id)}
              >
                <CloseCircle /> {t("portal_payments_table_menu_1")}
              </div>
            </TableDropdown>
          ),
        },
      ]);
    }
  );

  const selectedIds = useMemo(() => {
    return selectedFlatRows.map((i) => i.original.id);
  }, [selectedFlatRows]);

  const consumerTotal = useMemo(() => {
    return payments
      .filter((i) => selectedIds.includes(i?.id))
      .reduce(
        (prev, curr) =>
          prev +
          curr[currency === "gbp" ? "consumerTotalUK" : "consumerTotalHU"],
        0
      );
  }, [payments, selectedIds]);

  const total = useMemo(() => {
    return payments
      .filter((i) => selectedIds.includes(i?.id))
      .reduce(
        (prev, curr) => prev + curr[currency === "gbp" ? "totalUK" : "totalHU"],
        0
      );
  }, [payments, selectedIds]);

  const priceIds = useMemo(() => {
    let prices = payments
      .filter((i) => selectedIds.includes(i?.id))
      .map((i) => i?.[currency === "gbp" ? "priceIdUK" : "priceIdHU"]);
    let pricesStr = prices + "";
    return pricesStr;
  }, [payments, selectedIds]);

  const quantity = useMemo(() => {
    const obj = {};
    payments
      .filter((i) => selectedIds.includes(i?.id))
      .forEach((j) => {
        if (
          priceIds.includes(currency === "gbp" ? j?.priceIdUK : j?.priceIdHU)
        ) {
          (currency === "gbp" ? j?.priceIdUK : j?.priceIdHU)
            ?.split(",")
            ?.forEach((k) => {
              obj.hasOwnProperty(k) ? (obj[k] += 1) : (obj[k] = 1);
            });
        }
      });
    let qnts = Object.entries(obj).map((i) => i[1]);
    let qntStr = qnts + "";
    return qntStr;
  }, [selectedIds]);

  const removeItem = (id) => {
    setPayments((prev) => prev.filter((i) => i.id !== id));
  };

  const createPayment = async () => {
    const { data } = await api.post("createExpertPayment", {
      ref: "LD" + Math.floor(Date.now() / 1000),
      order_ids: payments
        .filter((i) => selectedIds.includes(i?.id))
        .map((j) => ({
          id: j?.id,
          type: j?.type,
          consumerTotal:
            currency === "gbp" ? j?.consumerTotalUK : j?.consumerTotalHU,
          total: currency === "gbp" ? j?.totalUK : j?.totalHU,
        })),
      expertId: user.id,
      currency,
      acr: discount,
      total: total,
      consumerTotal: consumerTotal,
      payment_type: "stripe",
    });

    return data;
  };

  const payForAll = async () => {
    if (mode === "error") {
      toast({
        title: t("Check selected payments"),
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      return;
    }

    setPayIsLoading(true);
    try {
      const paymentId = await createPayment();

      setPaymentId(paymentId);
      form.current.submit();
    } catch (err) {
      console.log("err", err);
      setIsError(err.message);
    } finally {
      setPayIsLoading(false);
    }
  };

  // TODO: admins notifications API
  const payFromAdmin = async () => {
    if (user.roleId != 2) return;

    if (confirm("Do you want to mark as paid?")) {
      try {
        setPayIsLoading(true);

        const paymentId = await createPayment();

        await centralApi.post("fakeStripePayment", {
          partners_payment: paymentId,
        });

        const emails = [];
        const {
          data: { list: admins },
        } = await centralApi.get("getAdmins");

        admins.forEach((i) => {
          if (i?.emails_permissions?.length) {
            const keys = i.emails_permissions.map((j) => j?.key);
            if (keys.includes("order_mark_as_paid")) {
              emails.push(i.email);
            }
          }
        });

        const { data: userByID } = await centralApi.get("userByID", {
          params: { userId: user.originalId },
        });

        const adminName = userByID?.firstName + " " + userByID?.lastName;

        let body = "";
        let subject = "";
        const metaRef = doc(db, "Meta", "notifications_txt");
        await getDoc(metaRef).then((metaDoc) => {
          if (metaDoc.exists()) {
            body = metaDoc
              .data()
              .email_new_mark_order_as_paid_body?.replace(
                "${userID}",
                user.originalId
              )
              ?.replace("${user_name}", adminName);
            subject = metaDoc.data().email_new_mark_order_as_paid_subtitle;
          }
        });

        const emailsRef = doc(collection(db, "Emails"));
        await setDoc(emailsRef, {
          body,
          id: emailsRef.id,
          recipients: emails.join(", "),
          send: serverTimestamp(),
          sent: false,
          subject,
        });

        history.push("/admin/success");
      } catch (err) {
        console.log("err", err);
        setIsError(err.message);
      } finally {
        setPayIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setPayments(initPayments);
  }, [initPayments]);

  useEffect(() => {
    setGlobalFilter(search || undefined);
  }, [search]);

  useEffect(() => {
    const arr = payments.filter((i) => selectedIds.includes(i?.id));

    if (
      arr.every((i) => !!i?.isTestUser) ||
      process.env.REACT_APP_ENV === "development"
    ) {
      setMode("_test");
    } else if (arr.every((i) => !i?.isTestUser)) {
      setMode("");
    } else {
      setMode("error");
    }
  }, [selectedIds]);

  return (
    <div>
      <div className="table table-with-action" {...getTableProps()}>
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div
              className="tr tr-with-select"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <div
                  className="th"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span className="table-sort">
                    {column.canSort ? (
                      <>
                        {column?.isSortedDesc && <> &#9660;</>}
                        {column?.isSortedDesc === false && <> &#9650;</>}
                        {column?.isSortedDesc === undefined && (
                          <span className="sort-arrows">
                            <span>&#9650;</span>
                            <span>&#9660;</span>
                          </span>
                        )}
                      </>
                    ) : null}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <div
                key={row.original.id}
                className="tr tr-with-select"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <div className="td" {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>

      <Pagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
      />

      <br />

      <Flex
        justifyContent={"space-between"}
        color={textColor}
        w={220}
        gap="1rem"
        marginLeft={"auto"}
      >
        <Text>{t("portal_payments_vat_title")}</Text> <b>20%</b>
      </Flex>
      <Flex
        justifyContent={"space-between"}
        color={textColor}
        w={220}
        gap="1rem"
        marginLeft={"auto"}
      >
        <Text>{t("portal_payments_total_title")}</Text>{" "}
        <b>
          {currency === "gbp" ? "£" : "€"}
          {(total / 100).toFixed(2)}
        </b>
      </Flex>

      <br />

      <form
        action={`${process.env.REACT_APP_API_URL}create_checkout_session`}
        method="post"
        ref={form}
      >
        <input
          type="hidden"
          name="CountryCode"
          value={`${currency === "gbp" ? "UK" : "HU"}${mode}`}
        />
        <input type="hidden" name="quantity" value={quantity} />
        <input
          type="hidden"
          name="price"
          value={[...new Set(priceIds.split(","))].join(",")}
        />
        <input type="hidden" name="discount" value={discount} />
        <input type="hidden" name="meta" value={paymentId} />

        <Flex alignItems="center" justifyContent="space-between">
          <Box mt="1rem">{user.paymentsIsUpdating && <DotsLoader />}</Box>
          <Flex
            justifyContent={"end"}
            flexDirection={{
              base: "column",
              md: "row",
            }}
            gap={{ base: "10px", md: "20px" }}
          >
            {user.roleId == 2 && (
              <button
                type="button"
                className="mark-as-pay"
                disabled={
                  !payments.length || payIsLoading || !selectedIds.length
                }
                onClick={payFromAdmin}
              >
                {payIsLoading ? t("portal_loading") : "Mark as paid"}
              </button>
            )}
            <button
              type="button"
              className="custom-btn"
              disabled={!payments.length || payIsLoading || !selectedIds.length}
              onClick={payForAll}
            >
              {payIsLoading
                ? t("portal_loading")
                : t("portal_payments_submit_button")}
            </button>
          </Flex>
        </Flex>
      </form>
    </div>
  );
};

export default PaymentTable;

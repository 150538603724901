import { Flex, Select, Spinner, Text } from "@chakra-ui/react";
import { db } from "../../../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as InfoIcon } from "assets/svg/info-circle.svg";
import { updateStorageOrders } from "helpers";
import centralApi from "centralApi";
import api from "api";

const QCForm = ({
  orderType,
  userData,
  orderId,
  prices,
  ADiscount,
  BDiscount,
  currency,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const user = useSelector((state) => state.user);

  const [dsOptions, setDsOptions] = useState([]);
  const [seOptions, setSeOptions] = useState([]);
  const [stOptions, setStOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [type, setType] = useState("");
  const [allergy, setAllergy] = useState("yes");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [createIsLoading, setCreateIsLoading] = useState(false);
  const [createIsError, setCreateIsError] = useState(false);
  const [idError, setIdError] = useState(false);

  const version =
    dsOptions.find((i) => i.id?.includes(type))?.version === "B" ? "B" : "A";

  const fetchTypes = async () => {
    try {
      setIsLoading(true);

      const metaDsRef = doc(db, "Meta", "shopify_ds_products");
      const metaSeRef = doc(db, "Meta", "shopify_se_products");
      const metaStRef = doc(db, "Meta", "shopify_st_products");
      const metaDsDoc = await getDoc(metaDsRef);
      const metaSeDoc = await getDoc(metaSeRef);
      const metaStDoc = await getDoc(metaStRef);
      let optionList = [];
      if (metaDsDoc.exists()) {
        const dsOptionsList = metaDsDoc
          .data()
          ?.ids?.sort((a, b) => a?.order - b?.order);
        setDsOptions(dsOptionsList);
        if (orderType === "ds") {
          optionList = dsOptionsList;
        }
      }

      if (metaSeDoc.exists()) {
        const seOptionsList = metaSeDoc
          .data()
          ?.ids?.sort((a, b) => a?.order - b?.order);
        setSeOptions(seOptionsList);
        if (orderType === "se") {
          optionList = seOptionsList;
        }
      }

      if (metaStDoc.exists()) {
        const stOptionsList = metaStDoc
          .data()
          ?.ids?.sort((a, b) => a?.order - b?.order);
        setStOptions(stOptionsList);
        if (orderType === "st") {
          optionList = stOptionsList;
        }
      }

      setOptions(optionList);

      let productsData = [];
      const productsRef = collection(db, "Products");
      const productsSnap = await getDocs(productsRef);
      if (!productsSnap.empty) {
        productsSnap.forEach((doc) => {
          productsData.push({ id: doc.id, ...doc.data() });
        });
      }
      setProducts(productsData);
    } catch (err) {
      console.log("err", err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();

    try {
      setCreateIsLoading(true);

      if (!userData?.id) {
        setIdError(true);
        return;
      }

      const orderProducts = options
        .find((i) => i?.id?.includes(type))
        ?.products.filter((j) => {
          if ("allergy" in j) {
            return (
              (j.allergy === true && allergy === "yes") ||
              (j.allergy === false && allergy === "no")
            );
          }
          return true;
        })
        .map((j) => ({ ref: j.ref, quantity: 1 }));

      if (orderId) {
        const { data: orderData } = await api.get("orderByID", {
          params: { orderId },
        });

        if (
          orderData &&
          (orderData?.status === "paid_shopify" ||
            orderData?.status === "paid_stripe")
        ) {
          await centralApi.post("updateOrder", { id: orderId, ...orderObj });
        }
      } else {
        const { data: userByID } = await centralApi.get("userByID", {
          params: { userId: userData?.id },
        });

        if (
          userByID &&
          userByID?.status !== "active" &&
          userByID?.status !== "waiting_delivery"
        ) {
          await centralApi.post("updateUser", {
            id: userData?.id,
            status: "waiting_payment",
          });
        }

        const orderObj = {
          ref: "LD" + Math.floor(Date.now() / 1000),
          type: orderType,
          product: {
            id: type,
            title: options.find((i) => i.id?.includes(type))?.name,
            products: orderProducts,
          },
          allergy,
          expertId: user.id,
          paymentId: null,
          status: "payment_needed",
          user_id: userData?.id,
          submitted: 1,
          currency: currency === "HU" ? "eur" : "gbp",
        };
        const {
          data: { insertId },
        } = await api.post("createOrder", orderObj);

        console.log(
          await api.get("orderByID", { params: { orderId: insertId } })
        );
      }

      history.push("/admin/orders");
    } catch (err) {
      console.log("err", err);
      setCreateIsError(true);
    } finally {
      setCreateIsLoading(false);
    }
  };

  useEffect(() => {
    setType("");
    if (orderType === "ds") {
      setAllergy("yes");
      setOptions(dsOptions);
    } else if (orderType === "se") {
      setAllergy("yes");
      setOptions(seOptions);
    } else if (orderType === "st") {
      setAllergy("no");
      setOptions(stOptions);
    }
  }, [orderType]);

  useEffect(() => {
    fetchTypes();
  }, []);

  const estimatedPrice =
    orderType === "ds"
      ? prices?.[
          `dsPricePartner${version === "A" ? ADiscount : BDiscount}${currency}`
        ]
      : orderType === "se"
      ? prices?.[
          `ip${options
            .find((i) => i?.id?.includes(type))
            ?.products[0].ref.replace(/p/gi, "")}PricePartner0${currency}`
        ]
      : orderType === "st"
      ? prices?.[
          `st${
            options.find((i) => i?.id?.includes(type))?.name?.split(" ")[0]
          }PricePartner0${currency}`
        ]
      : 0;

  return (
    <div>
      <h2 className="title">{t("portal_orders_new_qc_top_title")}</h2>
      <p className="descr descr-margin">
        {t("portal_orders_new_qc_top_description")}
      </p>

      {isLoading && (
        <Flex textAlign={"center"}>
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && (
        <p className="error" style={{ textAlign: "center" }}>
          {t("portal_error_message")}
        </p>
      )}
      {!isLoading && !isError && (
        <form onSubmit={onSubmit}>
          <Text fontWeight={600} fontSize={15} mb={"7px"}>
            {t("portal_orders_new_qc_product_title")}
          </Text>
          <Select
            w={{ lg: "440px", sm: "100%" }}
            h="44px"
            borderColor={"#3D2541"}
            placeholder={t("portal_orders_new_qc_product_placeholder")}
            isRequired
            value={type}
            onChange={(ev) => setType(ev.target.value)}
          >
            {options?.map((i) => (
              <option key={i?.id?.[0]} value={i?.id?.[0]}>
                {i.name}
              </option>
            ))}
          </Select>

          {orderType !== "st" && (
            <Flex mt="20px" alignItems={"center"} gap="12px">
              <Text as={"span"} fontWeight={600} fontSize={15} mr="14px">
                {t("portal_orders_new_qc_allergy_title")}:
              </Text>
              <label className="assessment-label" style={{ margin: 0 }}>
                <div className="custom-radio">
                  <input
                    type="radio"
                    name={"allergy"}
                    value={"yes"}
                    checked={allergy === "yes"}
                    onChange={() => setAllergy("yes")}
                  />
                  <span />
                </div>
                {t("portal_orders_new_qc_allergy_variant_1")}
              </label>
              <label className="assessment-label" style={{ margin: 0 }}>
                <div className="custom-radio">
                  <input
                    type="radio"
                    name={"allergy"}
                    value={"no"}
                    checked={allergy === "no"}
                    onChange={() => setAllergy("no")}
                  />
                  <span />
                </div>
                {t("portal_orders_new_qc_allergy_variant_2")}
              </label>
            </Flex>
          )}

          {type && (
            <>
              <Text
                className="title"
                mt={"30px"}
                mb={"20px"}
                fontWeight={"600"}
                fontSize={"26px"}
                lineHeight={"31px"}
                color={"#3D2541"}
              >
                {options.find((i) => i?.id?.includes(type))?.name}
              </Text>

              <div className="individual-products-table-scroll">
                <table className="individual-products-table">
                  <thead>
                    <tr className="individual-products-table-tr">
                      <th className="individual-products-table-th">
                        <span className="individual-products-table-td-accent">
                          {t("portal_orders_new_qc_table_title_1")}
                        </span>
                      </th>
                      <th className="individual-products-table-th">
                        {t("portal_orders_new_qc_table_title_2")}
                      </th>
                      <th className="individual-products-table-th">
                        {t("portal_orders_new_qc_table_title_3")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="individual-products-table-body">
                    {products
                      .filter((i) => {
                        const p = options.find((j) => j?.id?.includes(type))
                          ?.products;
                        const refs = p
                          .filter((j) => {
                            if ("allergy" in j) {
                              return (
                                (j.allergy === true && allergy === "yes") ||
                                (j.allergy === false && allergy === "no")
                              );
                            }
                            return true;
                          })
                          .map((j) => j?.ref);
                        return refs?.includes(i?.ref);
                      })
                      .sort(
                        (a, b) =>
                          a?.ref?.replace(/[^0-9]/g, "") -
                          b?.ref?.replace(/[^0-9]/g, "")
                      )
                      .map((j) => (
                        <tr
                          key={j?.id}
                          className="individual-products-table-tr"
                        >
                          <td className="individual-products-table-td">
                            <span className="individual-products-table-td-accent">
                              {j?.title}
                            </span>
                          </td>
                          <td className="individual-products-table-td">
                            {j?.ref}
                          </td>
                          <td className="individual-products-table-td">1</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {idError && (
            <Text color="red.400" mt="20px">
              {t("portal_orders_new_qc_error_1")}
            </Text>
          )}
          {createIsError && (
            <Text color="red.400" mt="20px">
              {t("portal_error_message")}
            </Text>
          )}

          <hr className="hr" />

          <Flex
            justifyContent={"space-between"}
            flexDirection={{ lg: "row", sm: "column" }}
            gap={{ lg: 0, sm: "20px" }}
          >
            <div className="new-order-price">
              {type && (
                <>
                  <InfoIcon /> {t("portal_orders_new_total")}
                  <span>
                    {currency === "UK" ? "£" : "€"} {estimatedPrice / 100}
                  </span>
                </>
              )}
            </div>
            <button
              type="submit"
              disabled={createIsLoading}
              className="custom-btn"
            >
              {createIsLoading
                ? t("portal_loading")
                : t("portal_orders_new_qc_bottom_button")}
            </button>
          </Flex>
        </form>
      )}
    </div>
  );
};

export default QCForm;
